<template>

  <div class="base-timer">
    <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g class="base-timer__circle">
        <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
        <path :stroke-dasharray="circleDashArray" :class="`base-timer__path-remaining ${remainingPathColor}`" d="M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0"></path>
      </g>
    </svg>
    <span class="base-timer__label">{{formattedTime}}</span>
  </div>

</template>

<script>
export default {
  data() {
    return {
      timePassed: 0,
      timerInterval: null,
    }
  },
  created() {
    this.FULL_DASH_ARRAY = 283
    this.WARNING_THRESHOLD = 90
    this.ALERT_THRESHOLD = 30
    this.TIME_LIMIT = 900

    this.COLOR_CODES = {
      info: {
        color: 'green',
      },
      warning: {
        color: 'orange',
        threshold: this.WARNING_THRESHOLD,
      },
      alert: {
        color: 'red',
        threshold: this.ALERT_THRESHOLD,
      },
    }
  },
  mounted() {
    this.startTimer()
  },
  computed: {
    timeLeft() {
      return this.TIME_LIMIT - this.timePassed
    },
    circleDashArray() {
      const rawTimeFraction = this.timeLeft / this.TIME_LIMIT
      const timeFraction =
        rawTimeFraction - (1 / this.TIME_LIMIT) * (1 - rawTimeFraction)

      return `${(timeFraction * this.FULL_DASH_ARRAY).toFixed(0)} ${
        this.FULL_DASH_ARRAY
      }`
    },
    remainingPathColor() {
      const { alert, warning, info } = this.COLOR_CODES
      if (this.timeLeft <= alert.threshold) {
        return alert.color
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color
      } else {
        return info.color
      }
    },
    formattedTime() {
      const minutes = Math.floor(this.timeLeft / 60)
      let seconds = this.timeLeft % 60

      if (seconds < 10) {
        seconds = `0${seconds}`
      }

      return `${minutes}:${seconds}`
    },
  },
  methods: {
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.timePassed += 1
        if (this.timeLeft === 0) {
          clearInterval(this.timerInterval)
        }
      }, 1000)
    },
  },
  beforeDestroy() {
    this.timerInterval && clearInterval(this.timerInterval)
  },
}
</script>
<style scoped lang="scss">
.base-timer {
  position: relative;
  width: 300px;
  height: 300px;
  color: #cbd9d9;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 10px;
  stroke: #e4e4e4;
}

.base-timer__path-remaining {
  stroke-width: 10px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: #cbd9d9;
}

.base-timer__path-remaining.orange {
  color: #ef7b3a;
}

.base-timer__path-remaining.red {
  color: #ed7275;
}

.base-timer__label {
  position: absolute;
  width: 300px;
  height: 300px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
}

@media (min-width: 576px) {
}
</style>
