<template>
  <div class="login-list-view d-flex flex-column">
    <div v-if="logins.length">
      <div v-for="u in logins" :key="u.id" class="d-flex flex-column align-items-center">
        <login-list-item
          :name="$helpers.displayUsername(u)"
          :subName="
            u.practices.length == 1 ? locationDisplayName(u.practice.name) : locationDisplayName(u.practice.name)
          "
          :logo="
            u.img ||
            (u.practices.length == 1 && logins.filter((pp) => pp.practice.id == u.practice.id).length == 1
              ? u.practice.logo
              : null)
          "
          :iconText="u.first_name[0] + u.last_name[0]"
          @selected="(e) => userSelected(u, u.practice.id)"
        />
        <div v-if="u.practices.length > 1" class="w-100 collapsible" :class="{ expanded: expandedUserId == u.id }">
          <login-list-item
            v-for="p in u.practices"
            :key="u.id + ' ' + p.id"
            :name="$helpers.displayUsername(u)"
            :subName="locationDisplayName(p.name)"
            :logo="p.logo"
            :iconText="p.name[0]"
            @selected="(e) => userSelected(u, p.id)"
          />
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <a class="dropdown-item px-3 py-2" v-on:click="$emit('newAccount')" href="javascript:void(0);">
        <font-awesome-icon
          icon="user-circle"
          size="3x"
          style="vertical-align: middle; background: #eff3f3; border-radius: 1.25em"
          class="mr-3"
        />&nbsp;
        <span
          class="d-none_ d-inline-block d-lg-inline-block text-left align-middle flex-grow-1"
          style="line-height: 1.25em; font-size: 1rem"
        >
          <span class="font-weight-bold text-truncate" style="max-width: 1250px">New sign in</span>
        </span>
        <font-awesome-icon :icon="['far', 'plus']" class="ml-3" />
      </a>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import LoginListItem from './LoginListItem.vue'
export default {
  components: { LoginListItem },
  data() {
    return { expandedUserId: null } //todo: errors and alerts from vuex
  },
  mounted() {
    //  this.expandedUserId = this.logins.length == 1 ? this.logins[0].id : null
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
      isLoginBarVisible: (state) => !!state.logins.length,
      logins: (state) => state.logins.filter((s) => !s.is_guest),
      locations: (state) => state.user.practice.other_locations,
    }),

    ...mapGetters('auth', ['isLoggedIn', 'isOrganization', 'availableLogins']),
  },
  methods: {
    userSelected(u, practiceId) {
      // this.expandedUserId = this.expandedUserId != u.id ? u.id : null

      this.$emit('selected', { userId: u.id, token: u.token, practiceId: practiceId || u.practice.id, email: u.email })
    },
    async signOut() {
      // const res = await this.$helpers.confirm('Logout on all devices?', 'Would you like to log out all devices?')
      this.$store.dispatch('auth/logout')
    },
    switchUser(userId) {
      const route = this.$route
      const router = this.$router
      const isChangingPractice = this.user.practice.id != this.otherUsers.find((s) => s.id == userId)?.practice
      this.$store.dispatch('auth/switchUser', userId).then(() => {
        if (route.name === 'Login' || isChangingPractice) router.push('/')
      })
    },
    async switchLocation(token) {
      //todo: handle expired tokens
      await this.$store.dispatch('auth/loginViaToken', { token })
      this.$router.push('/')
    },
    async switchPractice(id) {
      await this.$store.dispatch('auth/switchPractice', id)
      this.$router.push('/')
    },
    locationDisplayName(locationName) {
      let name = locationName.toLowerCase()
      //todo: prone to errors, can't have nested parentheses, a single ) would fail too
      return name.endsWith(')') ? name.match(/.*\(([^)]+)\)/)[1] : name
    },
  },
}
</script>

<style scoped>
.btn {
  border-radius: 0;
  color: var(--light-color);
}
.dropdown-toggle {
  padding: 0;
}
.dropdown-toggle::after {
  display: none;
}

.collapsible {
  max-height: 0;
  /* max-height: 700px; */
  transition: max-height 0.25s ease-out;
  overflow: hidden;
}
.collapsible.expanded {
  max-height: 700px;
}
</style>
