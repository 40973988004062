export default {
  UR: { name: 'Upper Right', fdi: 1 },
  UL: { name: 'Upper Left', fdi: 2 },
  LL: { name: 'Lower Left', fdi: 3 },
  LR: { name: 'Lower Right', fdi: 4 },

  1: { name: 'Upper Right Third Molar', fdi: 18 },
  2: { name: 'Upper Right Second Molar', fdi: 17 },
  3: { name: 'Upper Right First Molar', fdi: 16 },
  4: { name: 'Upper Right Second Bicuspid', fdi: 15 },
  5: { name: 'Upper Right First Bicuspid', fdi: 14 },
  6: { name: 'Upper Right Cuspid', fdi: 13 },
  7: { name: 'Upper Right Lateral Incisor', fdi: 12 },
  8: { name: 'Upper Right Central Incisor', fdi: 11 },

  9: { name: 'Upper Left Central Incisor', fdi: 21 },
  10: { name: 'Upper Left Lateral Incisor', fdi: 22 },
  11: { name: 'Upper Left Cuspid', fdi: 23 },
  12: { name: 'Upper Left First Bicuspid', fdi: 24 },
  13: { name: 'Upper Left Second Bicuspid', fdi: 25 },
  14: { name: 'Upper Left First Molar', fdi: 26 },
  15: { name: 'Upper Left Second Molar', fdi: 27 },
  16: { name: 'Upper Left Third Molar', fdi: 28 },

  17: { name: 'Lower Left Third Molar', fdi: 38 },
  18: { name: 'Lower Left Second Molar', fdi: 37 },
  19: { name: 'Lower Left First Molar', fdi: 36 },
  20: { name: 'Lower Left Second Bicuspid', fdi: 35 },
  21: { name: 'Lower Left First Bicuspid', fdi: 34 },
  22: { name: 'Lower Left Cuspid', fdi: 33 },
  23: { name: 'Lower Left Lateral Incisor', fdi: 32 },
  24: { name: 'Lower Left Central Incisor', fdi: 31 },

  25: { name: 'Lower Right Central Incisor', fdi: 41 },
  26: { name: 'Lower Right Lateral Incisor', fdi: 42 },
  27: { name: 'Lower Right Cuspid', fdi: 43 },
  28: { name: 'Lower Right First Bicuspid', fdi: 44 },
  29: { name: 'Lower Right Second Bicuspid', fdi: 45 },
  30: { name: 'Lower Right First Molar', fdi: 46 },
  31: { name: 'Lower Right Second Molar', fdi: 47 },
  32: { name: 'Lower Right Third Molar', fdi: 48 },

  A: { name: 'Upper Right Second Molar', fdi: 55 },
  B: { name: 'Upper Right First Molar', fdi: 54 },
  C: { name: 'Upper Right Cuspid', fdi: 53 },
  D: { name: 'Upper Right Lateral Incisor', fdi: 52 },
  E: { name: 'Upper Right Central Incisor', fdi: 51 },
  F: { name: 'Upper Left Central Incisor', fdi: 61 },
  G: { name: 'Upper Left Lateral Incisor', fdi: 62 },
  H: { name: 'Upper Left Cuspid', fdi: 63 },
  I: { name: 'Upper Left First Molar', fdi: 64 },
  J: { name: 'Upper Left Second Molar', fdi: 65 },

  K: { name: 'Lower Left Second Molar', fdi: 75 },
  L: { name: 'Lower Left First Molar', fdi: 74 },
  M: { name: 'Lower Left Cuspid', fdi: 73 },
  N: { name: 'Lower Left Lateral Incisor', fdi: 72 },
  O: { name: 'Lower Left Central Incisor', fdi: 71 },
  P: { name: 'Lower Right Central Incisor', fdi: 81 },
  Q: { name: 'Lower Right Lateral Incisor', fdi: 82 },
  R: { name: 'Lower Right Cuspid', fdi: 83 },
  S: { name: 'Lower Right First Molar', fdi: 84 },
  T: { name: 'Lower Right Second Molar', fdi: 85 }
}
