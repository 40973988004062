<template>
  <a class="dropdown-item text-capitalize px-3 py-2" v-on:click="$emit('selected')" href="javascript:void(0);">
    <div class="item-logo mr-3" style="min-width: 42px; width: 42px">
      <img v-if="logo" :src="logo" class="img-fluid" style="min-height: 40px; max-height: 40px" />
      <span
        v-else
        class="rounded-text-badge"
        :style="`height:40px; width:40px; font-size:1rem; background-color: #519f85; color:var(--light-color);`"
      >
        {{ iconText }}</span
      >
    </div>
    &nbsp;
    <!-- todo: dont d-none "new sign in" if there are no logins -->
    <span
      class="d-none d-sm-inline-block d-md-none d-lg-inline-block text-left align-middle flex-grow-1"
      style="line-height: 1.25em; font-size: 1rem"
    >
      <span class="font-weight-bold text-truncate" style="max-width: 1250px">{{ name }}</span>
      <template v-if="subName">
        <br class="d-inline-block" />
        <span class="d-inline-block text-muted text-capitalize text-truncate" style="max-width: 1250px">{{
          subName
        }}</span></template
      >
    </span>
    <font-awesome-icon :icon="['far', 'angle-right']" class="ml-3" />
  </a>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  props: ['name', 'subName', 'logo', 'iconText'],
  methods: {},
}
</script>

<style scoped>
.btn {
  border-radius: 0;
  color: var(--light-color);
}
</style>
