<template>
  <div class="">
    <!--<input type="checkbox" class="form-check-input" :checked="value" @input="$emit('input', $event.target.checked)" v-bind="$attrs">-->
    By clicking {{ name || 'NEXT' }}, I agree to the
    <router-link to="/privacy-policy" target="_blank"
      >Privacy Policy</router-link
    >,
    <router-link to="/terms-of-use" target="_blank">Terms of Use</router-link>
    and
    <router-link to="/business-agreement" target="_blank"
      >Business Associate Agreement</router-link
    >
  </div>
</template>
<script>
export default {
  props: ['value', 'name'],
}
</script>
