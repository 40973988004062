<template>
  <div class="autocomplete">
    <multiselect
      :value="{ display: value }"
      @input="actualSelection = $event && $event.display"
      ref="multiselect"
      id="ajax"
      track-by="display"
      placeholder="Enter Address"
      open-direction="bottom"
      :options="addresses"
      :multiple="false"
      :searchable="true"
      :loading="isLoading"
      :internal-search="internalSearch"
      :clear-on-select="false"
      :close-on-select="false"
      :options-limit="300"
      :preserve-search="false"
      :limit="5"
      :limit-text="limitText"
      :max-height="600"
      :show-no-results="false"
      :hide-selected="true"
      :custom-label="addressLabel"
      :select-label="''"
      @search-change="queryAutocompleteForSuggestions"
      @select="addressSelected"
    >
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{
            [props.option.streetLine, props.option.secondary].filter((s) => s).join(' ')
          }}</span>
          <span class="option__small ml-1">{{ props.option.city }}, {{ props.option.state }}</span>
          <small class="option__entries" v-if="props.option.entries > 1"
            ><b>{{ props.option.entries }} more entries</b></small
          >
        </div>
      </template>
      <template slot="noOptions"><span class="option__title">Start typing to search</span></template>
    </multiselect>

    <div v-if="error" class="text-danger">
      {{ error }}
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Vue from 'vue'
import * as SmartyStreetsSDK from 'smartystreets-javascript-sdk'
import * as sdkUtils from 'smartystreets-javascript-sdk-utils'
import Multiselect from 'vue-multiselect'

const SmartyStreetsCore = SmartyStreetsSDK.core
const websiteKey = '29277169640054556' // Your website key here '21102174564513388' //
const smartyStreetsSharedCredentials = new SmartyStreetsCore.SharedCredentials(websiteKey)

const usStreetClientBuilder = new SmartyStreetsCore.ClientBuilder(smartyStreetsSharedCredentials)

const autoCompleteClient = SmartyStreetsCore.buildClient.usAutocompletePro(smartyStreetsSharedCredentials) //autoCompleteClientBuilder.buildUsAutocompleteClient()
const usStreetClient = usStreetClientBuilder.buildUsStreetApiClient()

export default {
  name: 'Autocomplete',
  components: { Multiselect },
  props: ['value'],
  data() {
    return {
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      country: 'US',
      suggestions: [],
      error: '',

      addresses: [],
      isLoading: false,
      searchWithin: undefined, //preventDuplicateFromMultipleEntrySelect
      searchWithinFirst: undefined,
      internalSearch: true,
    }
  },
  methods: {
    addressLabel(option) {
      return option.display
    },
    addressSelected(option, id) {
      if (option.entries > 1) {
        const firstLine = `${option.streetLine} ${option.secondary}`.trim()
        this.searchWithinFirst = firstLine
        this.searchWithin = `${firstLine} (${option.entries}) ${option.city} ${option.state} ${option.zipcode}`
        this.$refs.multiselect.updateSearch(firstLine + ' ')
      } else {
        this.searchWithin = undefined
        this.searchWithinFirst = undefined
        this.address1 = `${option.streetLine} ${option.secondary}`.trim()
        this.city = option.city
        this.state = option.state
        this.zipCode = option.zipCode
        Vue.nextTick(() => {
          this.validateAddress()
        })
        this.$refs.multiselect.deactivate()
      }
    },
    limitText(count) {
      return `and ${count} other addresses`
    },
    async queryAutocompleteForSuggestions(query, id) {
      if (!query) return
      const lookup = new SmartyStreetsSDK.usAutocompletePro.Lookup(query)
      if (this.searchWithin && query.startsWith(this.searchWithinFirst)) lookup.selected = this.searchWithin
      const response = await autoCompleteClient.send(lookup)
      try {
        this.addresses = response.result.map((s) => ({
          ...s,
          display: `${s.streetLine} ${s.secondary}`.trim() + `, ${s.city} ${s.state} ${s.zipCode}`,
        }))
      } catch (e) {
        console.error(e)
        this.error = e.error
      }
      this.isLoading = false
    },
    validateAddress() {
      let lookup = new SmartyStreetsSDK.usStreet.Lookup()
      lookup.street = this.address1
      lookup.city = this.city
      lookup.state = this.state
      lookup.zipCode = this.zipCode

      usStreetClient
        .send(lookup)
        .then(this.updateStateFromValidatedAddress)
        .catch((e) => (this.error = e.error))
    },
    updateStateFromValidatedAddress(response) {
      const lookup = response.lookups[0]
      const isValid = sdkUtils.isValid(lookup)
      const isAmbiguous = sdkUtils.isAmbiguous(lookup)
      const isMissingSecondary = sdkUtils.isMissingSecondary(lookup)
      if (!isValid) {
        this.error = 'The address is invalid.'
      } else if (isAmbiguous) {
        this.error = 'The address is ambiguous.'
      } else if (isMissingSecondary) {
        this.error = 'The address is missing a unit number.'
      } else if (isValid) {
        const candidate = lookup.result[0]
        const meta = candidate.metadata
        this.address1 = candidate.deliveryLine1
        this.address2 = candidate.deliveryLine2
        this.city = candidate.components.cityName
        this.state = candidate.components.state
        this.zipCode = candidate.components.zipCode
        this.error =
          meta.rdi !== 'Residential' ? '' : 'This address appears to be residential please ensure it is correct' //meta.rdi !== 'Commercial' &&

        this.$emit('selected', {
          address: this.address1,
          address_2: this.address2,
          city: this.city,
          state: this.state,
          zip: this.zipCode,
          location: { latitude: meta.latitude, longitude: meta.longitude },
        })
      }
    },
  },
}
</script>

<style>
.multiselect__select {
  width: calc(1.5em + 0.75rem + 2px);
  height: calc(1.5em + 0.75rem);
}
.multiselect__tags {
  min-height: calc(1.5em + 0.75rem + 2px);
  height: calc(1.5em + 0.75rem + 2px);
  display: block;
  padding: 8px calc(1.5em + 0.75rem + 2px) 0 0.375rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  background: #fff;
  font-size: 0.85rem;
}
.multiselect__single {
  font-size: 0.85rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multiselect__placeholder {
  margin-bottom: 0.75em;
  font-size: 0.85em;
  padding: 0 0 0.375em 0.375rem;
}
.multiselect__input,
.multiselect__single {
  margin-bottom: 0.75em;
  font-weight: 500;
  color: #495057;
  font-weight: 500;
}
.multiselect__option--highlight,
.multiselect__option--highlight:after {
  /* background: #30c873; */
  /* #29a961; */
}
.multiselect__element {
  font-size: 14px;
}
.has-error .multiselect__tags {
  border: 1px solid #ff4c49;
}

.option__entries {
  margin-left: 5px;
}

.option__small {
  font-size: 75%;
  color: var(--muted-color);
}

.multiselect__option--highlight .option__small {
  color: var(--light-color);
}

.multiselect__option .no-results-option.option__title {
  font-size: 0.75rem;
  color: var(--body-color);
  font-weight: 500;
  margin-bottom: 1rem;
  display: block;
  letter-spacing: 0;
}
</style>
