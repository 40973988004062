<template>
  <div class="input-container" :class="{ valid: valid_password }">
    <input v-model="password" @input="checkPassword" :type="isPwVisible ? 'text' : 'password'" :errorMessages="error" autocomplete="new-password" name="password" class="mb-2 form-control form-control-lg form-control-xl" placeholder="Password (min. 8 chars)" />
    <div class="password-reveal">
      <a href="javascript:void(0)" @click.prevent="isPwVisible = !isPwVisible">
        <font-awesome-icon :icon="['far', isPwVisible ? 'eye-slash' : 'eye']" />
      </a>
    </div>
    <ul>
      <li v-bind:class="{ is_valid: contains_eight_characters }" class="text-muted" style="font-size:.75rem">
        Minimum 8 characters
      </li>
    </ul>

    <div class="checkmark_container" :class="{ show_checkmark: valid_password }">
      <svg width="50%" height="50%" viewBox="0 0 140 100">
        <path class="checkmark" :class="{ checked: valid_password }" d="M10,50 l25,40 l95,-70" />
      </svg>
    </div>
  </div>
</template>

<script>
/*eslint no-useless-escape: "warn"*/

export default {
  props: ['value', 'error'],
  components: {},
  data() {
    return {
      password: '',
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_lowercase: false,
      contains_special_character: false,
      valid_password: false,
      isPwVisible: false,
    }
  },
  methods: {
    checkPassword() {
      this.password_length = this.password.length
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/

      this.contains_eight_characters = this.password_length >= 8
      this.contains_number = true || /\d/.test(this.password)
      this.contains_uppercase = true || /[A-Z]/.test(this.password)
      this.contains_lowercase = true || /[a-z]/.test(this.password)
      this.contains_special_character = true || format.test(this.password)

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_lowercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true
      } else {
        this.valid_password = false
      }
      this.$emit('input', {
        value: this.password,
        isValid: this.valid_password,
      })
    },
  },
}
</script>

<style scoped>
ul {
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
}

li {
  color: #525f7f;
  position: relative;
  list-style-type: none;
  list-style: none;
}

li:before {
  content: '';
  width: 0%;
  height: 1px;
  background: var(--success-color);
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* Password Input --------- */

.input-container {
  position: relative;
}

/* Checkmark & Strikethrough --------- */

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 100%;
}
.password-reveal {
  position: absolute;
  top: 12px;
  right: 15px;
  transition: all 0.5s ease;
}
.valid .password-reveal {
  right: 45px;
}

.checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--success-color);
  width: 25px;
  height: 25px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.checked {
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
